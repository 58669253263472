import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import SEO from "../components/SEO/index"
import StyledBackgroundPromotion from "../components/BackgroundPromotion"
import HeroPromotion from "../components/Sections/HeroPromotion"
import { Section } from "../components/CompoundComponents/Section"
import { Columns, Column } from "../components/CompoundComponents/Columns"

import "./mystyles.scss"

const PromotionPage = ({ location, intl }) => {
  return (
    <>
      <SEO title="Fanomed clinics" description="Fanomed clinics" />
      <StyledBackgroundPromotion>
        <HeroPromotion location={location} />
      </StyledBackgroundPromotion>
      <Section white>
        <Columns>
          <Column is8>
            <div className="content">
              <p>
                {intl.formatMessage({
                  id: `promotion.desc-2`,
                })}
              </p>
              <p>
                {intl.formatMessage({
                  id: `promotion.desc-3`,
                })}
              </p>
            </div>
          </Column>
          <Column is4>
            <article className="message is-warning is-shadow">
              <div className="message-body">
                <div className="content">
                  <p className="is-size-5-mobile has-text-weight-bold">
                    {intl.formatMessage({
                      id: `promotion.desc-4`,
                    })}
                  </p>
                </div>
              </div>
            </article>
          </Column>
        </Columns>
      </Section>
    </>
  )
}

export default injectIntl(PromotionPage)

import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { Columns, Column } from "../CompoundComponents/Columns"
import {
  Hero,
  HeroTitle,
  HeroBody,
  HeroHead,
  HeroSubTitle,
} from "../CompoundComponents/Hero"
import NavBar from "../navbar"

const HeroPromotion = ({ intl, location }) => {
  return (
    <>
      <Hero>
        <HeroHead>
          <NavBar location={location} />
        </HeroHead>
        <HeroBody>
          <Columns vcentered>
            <Column is12 centered>
              <HeroTitle>
                {intl.formatMessage({ id: "promotion.title" })}
              </HeroTitle>
              <HeroSubTitle>
                {intl.formatMessage({ id: "promotion.desc" })}
              </HeroSubTitle>
              <Link
                className="button is-rounded is-uppercase is-primary has-text-weight-bold has-family-pt-sans"
                to="/booking"
              >
                {intl.formatMessage({
                  id: `header.nav.item-6`,
                })}
              </Link>
            </Column>
          </Columns>
        </HeroBody>
      </Hero>
    </>
  )
}

export default injectIntl(HeroPromotion)
